import { Component, OnInit, Injector, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { ProductSubModel } from '../../product-sub/models/product-sub.model';
import { ParameterModel } from '../models/parameter.model';
import { ParameterService } from '../services/parameter.service';

@Component({
  selector: 'app-modal-parameter',
  templateUrl: './parameter.modal.html',
})
export class ParameterModal extends BaseComponentDirective implements OnInit{
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public productCategory: ProductSubModel;
  public viewMode = false;
  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ParameterModel>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private parameterService: ParameterService
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      categoryName: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.productCategory = this.data.productCategory as ProductSubModel;
    this.mode = this.data.mode;


  }

  save(): void {
  }
}
