<mat-dialog-content style="width: 950px;">

  <div class="mat-card-title px-2 py-3">
    <h3 class="pl-2">{{'pages.customer.customer' | translate}}</h3>
  </div>
  <div class="mat-card-content px-3 rounded">
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label>{{'common.search' | translate}}</label>
          <div class="input-group mb-3">
            <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
            <div class="input-group-append">
            <span class="input-group-text" id="suffix_password_show">
                <span class="icon-search"></span>
            </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="mat-card-content px-3 pb-3">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th class="text-nowrap text-end">{{'pages.customer.id' | translate}}</th>
          <th class="text-nowrap">{{'pages.customer.customer-name' | translate}}</th>
          <th class="text-nowrap">{{'pages.area.area-name' | translate}}</th>
          <th class="text-nowrap">{{'pages.area-sub.name' | translate}}</th>
          <th class="text-nowrap">{{'pages.customer.contact-person' | translate}}</th>
          <th class="text-nowrap">{{'pages.customer.phone-number' | translate}}</th>
          <th class="text-nowrap">{{'pages.customer.address' | translate}}</th>
          <th class="sticky-column"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let customer of pageItems">
          <td class="text-nowrap text-end">{{customer.id}}</td>
          <td class="text-nowrap">{{customer.customerName}}</td>
          <td class="text-nowrap">{{customer.areaId}}</td>
          <td class="text-nowrap">{{maskingColumn('areaName', 'id', areas, customer.areaId)}}</td>
          <td class="text-nowrap">{{maskingColumn('subAreaName', 'id', subAreas, customer.subAreaId)}}</td>
          <td class="text-nowrap">{{customer.phoneNumbers}}</td>
          <td class="text-nowrap">{{customer.address}}</td>
          <td class="sticky-column">
            <div class="btn-group" role="group" aria-label="Button Action">
              <input type="radio" name="picker" [value]="customer" [(ngModel)]="selectedCustomer">
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mat-card-content">
    <div class="row mb-4">
      <div class="col-12 text-center">
        <app-pagination
          (goPage)="toPage($event)"
          [pagesToShow]="showPages"
          [page]="pageNo"
          [perPage]="pageSize"
          [count]="totalRow"></app-pagination>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions  class="justify-content-end pe-4">
  <button type="button" (click)="choose()" mat-raised-button class="py-1" color="primary">{{'common.choose' | translate}}</button>
  <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
</mat-dialog-actions>
