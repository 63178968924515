<ul class="pagination">
    <li (click)="toFirst()" class="page-item">
      <a class="page-link">{{'common.first' | translate}}</a>
    </li>
  <ng-container *ngFor="let pageNum of getPages()">
    <li (click)="onPage(pageNum)" class="page-item active" *ngIf="pageNum === (page)">
      <a class="page-link ">{{pageNum}}</a>
    </li>
    <li (click)="onPage(pageNum)" class="page-item" *ngIf="!(pageNum === (page))">
      <a class="page-link">{{pageNum}}</a>
    </li>
  </ng-container>
    <li (click)="toLast()" class="page-item">
      <a class="page-link">{{'common.last' | translate}}</a>
    </li>
</ul>
