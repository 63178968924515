<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50">

      <mat-accordion class="example-headers-align" multi >
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'pages.item-group.item-group' | translate}}
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div class="pb-3">
            <form [formGroup]="form" (submit)="save()">
              <div class="row">
                <div class="col-4">
                  <img *ngIf="itemGroup" alt="" class="rounded img-fluid shadow-sm image-round-multiply" [src]="itemImageBaseURL + itemGroup.imageName" />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <app-text-field [readonly]="readonly" [label]="'pages.item-group.item-group-code' | translate" formControlName="itemGroupCode"></app-text-field>
                </div>
                <div class="col-6">
                  <app-text-field [readonly]="readonly" [label]="'pages.item-group.item-group-name' | translate" formControlName="itemGroupName" ></app-text-field>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <app-select [readonly]="readonly" [items]="products" [bindLabel]="'productName'" [bindValue]="'id'" [label]="'pages.product.product' | translate" formControlName="productId"></app-select>
                </div>
                <div class="col-6">
                  <app-select [readonly]="readonly" [items]="subProducts" [bindLabel]="'subProductName'" [bindValue]="'id'" [label]="'pages.product-sub.product-sub' | translate" formControlName="subProductId"></app-select>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <app-select [readonly]="readonly" [items]="units" [bindLabel]="'key'" [bindValue]="'value'" [label]="'pages.item.unit' | translate" formControlName="unit"></app-select>
                </div>
                <div class="col-4">
                  <app-number-field [readonly]="readonly" [label]="'pages.item.buy-price' | translate" formControlName="buyPrice"></app-number-field>
                </div>
                <div class="col-4">
                  <app-number-field [readonly]="readonly" [label]="'pages.item.standard-price' | translate" formControlName="standardPrice"></app-number-field>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <app-text-field [readonly]="readonly" [label]="'pages.item-group.supplier-name' | translate" formControlName="supplierName"></app-text-field>
                </div>
                <div class="col-4">
                  <app-datepicker [readonly]="readonly" [label]="'pages.item.price-change-date' | translate" formControlName="priceChangeDate"></app-datepicker>
                </div>

              </div>
              <div class="row mt-3">
                <div class="col-12 justify-content-end text-end">
                  <button mat-raised-button color="primary">{{'common.edit' | translate}} {{'pages.item-group.item-group' | translate}}</button>
                </div>
              </div>
            </form>
          </div>

        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'pages.item.item' | translate}}
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div class="">
            <div class="row">
              <div class="col-4">
                <label>{{'common.search' | translate}}</label>
                <div class="input-group mb-3">
                  <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
                  <div class="input-group-append">
                  <span class="input-group-text" id="suffix_password_show">
                      <span class="icon-search"></span>
                  </span>
                  </div>
                </div>
              </div>
              <div class="col-8 pt-3 text-end">
                <button (click)="create()" mat-raised-button color="primary" class="bg-brand text-white me-2">{{'common.create' | translate}}</button>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="text-nowrap text-end">{{'pages.item.id' | translate}}</th>
                    <th class="text-nowrap">{{'pages.item.sku' | translate}}</th>
                    <th class="text-nowrap">{{'pages.item.unit' | translate}}</th>
                    <th class="text-nowrap">{{'pages.item.area' | translate}}</th>
                    <th class="text-nowrap">{{'pages.item.storage' | translate}}</th>
                    <th class="text-nowrap">{{'pages.item.margin' | translate}}</th>
                    <th class="text-nowrap text-end">{{'pages.item.current-stock' | translate}}</th>
                    <th class="text-nowrap text-end">{{'pages.item.sell-price' | translate}}</th>
                    <th class="sticky-column text-center" style="width: 200px;">{{'common.action' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let d of pageItems">
                    <td class="text-end text-nowrap">{{d.id}}</td>
                    <td class="text-left text-nowrap">{{d.sku}}</td>
                    <td class="text-left text-nowrap">{{d.unit}}</td>
                    <td class="text-left text-nowrap">{{d.areaName}}</td>
                    <td class="text-left text-nowrap">{{d.storageName}}</td>
                    <td class="text-left text-nowrap">{{maskingColumn('marginName', 'id', margins, d.marginId) + ' ' + maskingColumn('margin', 'id', margins, d.marginId) + '%'}}</td>
                    <td class="text-end text-nowrap">{{d.currentStock ? d.currentStock : '0'}}</td>
                    <td class="text-end text-nowrap">{{d.sellPrice|number: '1.2-2'}}</td>
                    <td class="sticky-column">
                      <div class="btn-group" role="group" aria-label="Button Action">
                        <button (click)="view(d)" type="button" mat-button color="primary">
                          <mat-icon>fullscreen</mat-icon>
                        </button>
                        <button (click)="edit(d)" mat-button class="text-orange" color="primary">
                          <mat-icon>edit_square</mat-icon>
                        </button>
                        <button (click)="delete(d)" type="button"  mat-button color="warn">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="">
            <div class="row mt-2 mb-4">
              <div class="col-12 text-center">
                <app-pagination
                  (goPage)="toPage($event)"
                  [pagesToShow]="showPages"
                  [page]="pageNo"
                  [perPage]="pageSize"
                  [count]="totalRow"></app-pagination>
              </div>
            </div>
          </div>

        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'pages.supplier.supplier' | translate}}
            </mat-panel-title>

          </mat-expansion-panel-header>

          <app-page-item-supplier-item [itemGroup]="itemGroup" *ngIf="itemGroup" [itemGroupId]="itemGroup.id" (result)="getResult($event)"></app-page-item-supplier-item>

        </mat-expansion-panel>
      </mat-accordion>


    </div>
  </div>
</div>
