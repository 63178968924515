import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {ProductModel} from '../../../master/product/models/product.model';
import {ProductSubModel} from '../../../master/product-sub/models/product-sub.model';
import {SupplierModel} from '../../../supplier/supplier/models/supplier.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductService} from '../../../master/product/services/product.service';
import {ProductSubService} from '../../../master/product-sub/services/product-sub.service';
import {SupplierService} from '../../../supplier/supplier/services/supplier.service';
import {ItemModel} from '../models/item.model';
import {ItemService} from '../services/item.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-picker-item',
  templateUrl: './item.picker.html',
})
export class ItemPicker extends BasePageComponentDirective<ItemModel> implements OnInit{
  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  suppliers: SupplierModel[] = [];
  public result = new EventEmitter();
  selectedItem: ItemModel;
  itemImageBaseURL = environment.storage + '/images/item-group/';

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ItemPicker>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemService: ItemService,
    private productService: ProductService,
    private subProductService: ProductSubService,
    private supplierService: SupplierService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.products = await this.productService.findAllNoPaging();
    this.subProducts = await this.subProductService.findAllNoPaging(0);
    this.suppliers = await this.supplierService.findAllNoPaging();
    this.query();

  }

  query(): void {
    this.pageQuery.pageSize = 5;
    this.itemService.findByAreaIdPaged(this.data.areaId, this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  choose(): void {
    this.result.emit(this.selectedItem);
    this.dialogRef.close();
  }
}
