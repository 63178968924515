import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {ItemGroupService} from '../services/item-group.service';
import {ProductService} from '../../../master/product/services/product.service';
import {ProductSubService} from '../../../master/product-sub/services/product-sub.service';
import {SupplierService} from '../../../supplier/supplier/services/supplier.service';
import {ProductModel} from '../../../master/product/models/product.model';
import {ProductSubModel} from '../../../master/product-sub/models/product-sub.model';
import {SupplierModel} from '../../../supplier/supplier/models/supplier.model';
import {ItemGroupModel} from '../models/item-group.model';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-picker-item-group',
  templateUrl: './item-group.picker.html',
})
export class ItemGroupPicker extends BasePageComponentDirective<ItemGroupModel> implements OnInit{
  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  suppliers: SupplierModel[] = [];
  public result = new EventEmitter();
  selectedItemGroup: ItemGroupModel;
  itemImageBaseURL = environment.storage + '/images/item-group/';

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ItemGroupPicker>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemGroupService: ItemGroupService,
    private productService: ProductService,
    private subProductService: ProductSubService,
    private supplierService: SupplierService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.products = await this.productService.findAllNoPaging();
    this.subProducts = await this.subProductService.findAllNoPaging(0);
    this.suppliers = await this.supplierService.findAllNoPaging();
    this.query();
  }

  query(): void {
    this.pageQuery.pageSize = 5;
    this.itemGroupService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  choose(): void {
    console.log(this.products, this.selectedItemGroup, this.subProducts);
    const p = this.products.find(px => parseInt(px.id, 10) === parseInt(this.selectedItemGroup.productId, 10));
    const ps = this.subProducts.find(psx => parseInt(psx.id, 10) === parseInt(this.selectedItemGroup.subProductId, 10));
    this.selectedItemGroup.productName = p.productName;
    this.selectedItemGroup.subProductName = ps.subProductName;
    this.result.emit(this.selectedItemGroup);
    this.dialogRef.close();
  }
}
