import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SupplierOrderDetailModel, SupplierOrderModel} from '../models/supplier-order.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SupplierOrderService} from '../services/supplier-order.service';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {
  CRUD_MODE_CREATE,
  CRUD_MODE_EDIT,
  CRUD_MODE_VIEW,
  IN_STOCK_LIST,
  STATUS_LIST,
  UNIT_LIST
} from '../../../../constants/common.constant';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {BaseComponentDirective} from '../../../../shared/base/base.component';
import {SupplierModel} from '../../supplier/models/supplier.model';
import {SupplierService} from '../../supplier/services/supplier.service';
import {SupplierOrderModal} from '../modals/supplier-order.modal';
import {ItemGroupModel} from '../../../item/item-group/models/item-group.model';
import {ItemGroupPicker} from '../../../item/item-group/modals/item-group.picker';
import {ItemService} from '../../../item/item/services/item.service';
import {MatAccordion} from '@angular/material/expansion';
import {SupplierPicker} from '../../supplier/modals/supplier.picker';

@Component({
  selector: 'app-page-supplier-order-form',
  templateUrl: './supplier-order-form.page.html',
  styleUrls: ['../supplier-order.scss']
})
export class SupplierOrderFormPageComponent extends BaseComponentDirective implements OnInit, AfterViewInit {

  supplierOrder: SupplierOrderModel;
  form: FormGroup;
  mode = '';
  hideOnNew = true;
  img: any;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  disableButton = true;
  total = 0;

  suppliers: SupplierModel[] = [];
  statuses = [];
  stockStatuses = IN_STOCK_LIST;

  protected readonly UNIT_LIST = UNIT_LIST;

  orderDetails: SupplierOrderDetailModel[] = [];
  itemGroups: ItemGroupModel[] = [];

  generateInvoiceCounter = 0;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private supplierOrderService: SupplierOrderService,
    private supplierService: SupplierService,
    private itemService: ItemService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);

    this.form = this.formBuilder.group({
      supplierId: [null, [Validators.required]],
      supplierName: [null, []],
      address: [null, []],
      contactPerson: [null, []],
      phone: [null, []],
      internalInvoiceNo: [null, [Validators.required]],
      supplierInvoiceNo: [null, []],
      orderDate: [null, [Validators.required]],
      receivedDate: [null, []],
      tax: [0, []],
      supplierDiscount: [0, []],
      otherAddition: [0, []],
      otherSubtraction: [0, []],
      orderedBy: [null, []],
      status: ['draft', [Validators.required]],
      stockStatus: ['none', []],
      paidDate: [null, []],
      verifiedDate: [null, []],
      stockedDate: [null, []],
      note: [null, []],
    });
  }
  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 1500);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.form.controls.status.disable({onlySelf: true});
    switch (this.activatedRoute.snapshot.params.action) {
      case 'add-new': {
        this.mode = CRUD_MODE_CREATE;
        this.statuses = STATUS_LIST;
        this.hideOnNew = false;
        break;

      }
      case 'edit': {
        this.mode = CRUD_MODE_EDIT;
        const id = this.activatedRoute.snapshot.params.id;
        this.supplierOrder = await this.supplierOrderService.findOne(id);

        this.supplierOrderService.downloadEvidenceFile(id).then(blob => {
          this.img = URL.createObjectURL(blob);
        }, error => {
          console.error('Error fetching image:', error);
        });

        this.fillFormAndDetails();
        this.statuses = STATUS_LIST;
        if (this.supplierOrder.status !== 'draft') {
          const index = this.statuses.findIndex(sx => sx.key === 'draft');
          if (index > -1) {
            this.statuses.splice(index, 1);
          }
        } else {
          this.hideOnNew = false;
        }
        this.supplierOrder.details.forEach(d => {
          this.total += d.subtotal;
        });
        this.orderDetails = this.supplierOrder.details;
        this.disableButton = false;
        switch (this.supplierOrder.status) {
          case 'sent':
          case 'paid':
          case 'pending':
          case 'received':
          case 'verified': {
            this.form.controls.supplierId.disable();
            this.form.controls.orderDate.disable();
            break;
          }
        }
        break;
      }
      case 'view': {
        this.mode = CRUD_MODE_VIEW;
        const id = this.activatedRoute.snapshot.params.id;
        this.form.disable();
        this.disableButton = true;
        this.supplierOrder = await this.supplierOrderService.findOne(id);
        this.fillFormAndDetails();
        this.statuses = STATUS_LIST;
        if (this.supplierOrder.status !== 'draft') {
          const index = this.statuses.findIndex(sx => sx.key === 'draft');
          if (index > -1) {
            this.statuses.splice(index, 1);
          }
        }
        this.supplierOrder.details.forEach(d => {
          this.total += d.subtotal;
        });
        this.orderDetails = this.supplierOrder.details;

        break;
      }
      default:
        this.mode = CRUD_MODE_VIEW;
    }

    this.breadcrumbService.changeMessage([
      {label: 'pages.supplier.supplier', active: false},
      {label: 'pages.supplier.supplier-order', active: false, route: '/supplier/order'},
      {label: 'pages.supplier.supplier-order-form', active: true, route: ''}
    ]);

    this.form.controls.status.valueChanges.subscribe(r => {
      if (this.supplierOrder && this.supplierOrder.status) {
        this.supplierOrder.status = r;
      }
    });

    this.suppliers = await this.supplierService.findAllNoPaging();
    this.form.controls.supplierId.valueChanges.subscribe(async r => {
      this.disableButton = (!r);
      if (this.mode === CRUD_MODE_CREATE) {
        this.generateInvoiceNo(r).then();
      }
    });

    this.form.controls.orderDate.valueChanges.subscribe(async r => {
      if (this.mode === CRUD_MODE_CREATE) {
        this.generateInvoiceNo(r).then();
      }
    });
  }

  openSupplierPicker(): void {
    const dialog = this.dialog.open(SupplierPicker, {});
    dialog.componentInstance.result.subscribe(r => {
      if (r) {
        this.form.controls.supplierId.setValue(r.id);
        this.form.controls.supplierName.setValue(r.supplierName);
        this.form.controls.contactPerson.setValue(r.contactPerson);
        this.form.controls.phone.setValue(r.phoneNumber);
        this.form.controls.address.setValue(r.address);
      }
    });
  }

  buttonToShowUp(type: string): boolean {
    switch (type) {
      case 'sent':
      case 'save': {
        if (!this.supplierOrder) {
          return true;
        }else {
          if (this.supplierOrder.status.toLowerCase() === 'draft') {
            return true;
          }
        }
        return false;
      }
      case 'print': {
        return !!this.supplierOrder;
      }
      case 'verify': {
        return this.supplierOrder && this.supplierOrder.status.toLowerCase() === 'received';
      }
      case 'paid': {
        return this.supplierOrder && (this.supplierOrder.status.toLowerCase() === 'pending' || this.supplierOrder.status.toLowerCase() === 'verified');
      }
    }
    return true;
  }

  cleanupStatus(): void {
    const toDelete = ['PENDING PAYMENT', 'VERIFIED', 'PAID'];
    this.statuses.forEach(_ => {
      toDelete.forEach(d => {
        const index = this.statuses.findIndex(sx => sx.key === d);
        if (index > -1) {
          this.statuses = this.statuses.splice(index, 1);
        }
      });
    });
  }

  async generateInvoiceNo(r: any): Promise<void> {
    if (r && this.generateInvoiceCounter === 0 || this.generateInvoiceCounter === 1) {
      this.generateInvoiceCounter++;
    } else {
      if (!r) {
        this.generateInvoiceCounter--;
        this.form.controls.internalInvoiceNo.setValue(null);
      }
    }

    if (this.generateInvoiceCounter === 2) {
      const invoiceNo = await this.supplierOrderService.generateInvoice(
        this.form.controls.supplierId.value,
        this.date.toDbDate(this.form.controls.orderDate.value));
      this.form.controls.internalInvoiceNo.setValue(invoiceNo.value.toUpperCase());
    }
  }

  async fillFormAndDetails(): Promise<void> {
    const supplier = await this.supplierService.findOne(this.supplierOrder.supplierId);
    this.form.setValue({
      supplierId: this.supplierOrder.supplierId,
      supplierName: supplier.supplierName,
      address: supplier.address,
      phone: supplier.phoneNumber,
      contactPerson: supplier.contactPerson,
      internalInvoiceNo: this.supplierOrder.internalInvoiceNo,
      supplierInvoiceNo: this.supplierOrder.supplierInvoiceNo,
      orderDate: this.supplierOrder.orderDate,
      receivedDate: this.supplierOrder.receivedDate,
      tax: this.supplierOrder.tax,
      supplierDiscount: this.supplierOrder.supplierDiscount,
      otherAddition: this.supplierOrder.otherAddition,
      otherSubtraction: this.supplierOrder.otherSubtraction,
      orderedBy: this.supplierOrder.orderedBy,
      status: this.supplierOrder.status,
      stockStatus: this.supplierOrder.stockStatus,
      paidDate: this.supplierOrder.paidDate,
      verifiedDate: this.supplierOrder.verifiedDate,
      stockedDate: this.supplierOrder.stockedDate,
      note: this.supplierOrder.note,
    });
  }

  doBeforeSave(mode: string, date: Date): void {
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.supplierOrder.id : null,
      supplierId: this.form.controls.supplierId.value,
      internalInvoiceNo: this.form.controls.internalInvoiceNo.value,
      supplierInvoiceNo: this.form.controls.supplierInvoiceNo.value,
      orderDate: this.form.controls.orderDate.value,
      receivedDate: (['received', 'verified', 'pending', 'paid'].find(f => f === mode)) ? date : null,
      tax: this.form.controls.tax.value,
      supplierDiscount: this.form.controls.supplierDiscount.value,
      otherAddition: this.form.controls.otherAddition.value,
      otherSubtraction: this.form.controls.otherSubtraction.value,
      orderedBy: this.form.controls.orderedBy.value,
      status: mode,
      stockStatus: this.form.controls.stockStatus.value,
      paidDate: (mode === 'paid') ? this.form.controls.paidDate.value : null,
      verifiedDate: (['verified', 'pending', 'paid'].find(f => f === mode)) ? date : null,
      stockedDate: this.form.controls.stockedDate.value,
      note: this.form.controls.note.value,
      details: []
    };

    this.orderDetails.forEach(od => {
      od.lastBuyPrice = this.ui.moneyFormatToNumber(od.lastBuyPrice);
      od.buyPrice = this.ui.moneyFormatToNumber(od.buyPrice);
      od.realQuantity = this.ui.moneyFormatToNumber(od.realQuantity);
      od.quantity = this.ui.moneyFormatToNumber(od.quantity);
      od.standardPrice = this.ui.moneyFormatToNumber(od.standardPrice);
      payload.details.push(od);
    });

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.internalInvoiceNo}` : `#${payload.id} - ${payload.internalInvoiceNo}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.supplierOrderService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.supplierOrderService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }

    this.doBeforeSave('draft', this.date.nowToDbDate());

  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });

    this.router.navigate(['/supplier/order']).then();
  }

  getNewBuyPrice(d: any, index: number, price: any): void {
    if (!this.orderDetails[index].buyPrice) {
      this.orderDetails[index].buyPrice = 0;
    }
    this.orderDetails[index].buyPrice = price;
    this.orderDetails[index].subtotal = this.ui.moneyFormatToNumber(price) *
        this.ui.moneyFormatToNumber(this.orderDetails[index].realQuantity);
    this.total = 0;
    this.orderDetails.forEach(dx => {
      this.total += dx.subtotal;
    });
  }

  getQuantity(d: any, index: number, qty: any): void {
    if (!this.orderDetails[index].buyPrice) {
      this.orderDetails[index].buyPrice = 0;
    }
    this.orderDetails[index].quantity = qty;
    this.orderDetails[index].subtotal = this.ui.moneyFormatToNumber(qty) * this.ui.moneyFormatToNumber(this.orderDetails[index].buyPrice);
    this.total = 0;
    this.orderDetails.forEach(dx => {
      this.total += dx.subtotal;
    });
  }

  getRealQuantity(d: any, index: number, qty: any): void {
    if (!this.orderDetails[index].buyPrice) {
      this.orderDetails[index].buyPrice = 0;
    }
    if (!qty) {
      qty = 0;
    }
    this.orderDetails[index].realQuantity = qty;
    this.orderDetails[index].subtotal = this.ui.moneyFormatToNumber(qty) * this.ui.moneyFormatToNumber(this.orderDetails[index].buyPrice);
    this.total = 0;
    this.orderDetails.forEach(dx => {
      this.total += dx.subtotal;
    });
  }

  create(): void {
    const dialog = this.dialog.open(ItemGroupPicker, {});

    dialog.componentInstance.result.subscribe(async r => {
      if (r) {
        const justStocks = await this.itemService.findStockAll(r.id);
        let total = 0;
        justStocks.forEach(i => {
          total += parseFloat(i.currentStock);
        });
        this.orderDetails.push({
          itemGroupId: r.id,
          itemGroupName: r.itemGroupName,
          quantity: 0,
          subtotal: 0,
          currentStock: total,
          buyPrice: r.buyPrice,
          standardPrice: r.standardPrice,
          unit: r.unit
        });
      }
    });
  }

  view(d: SupplierOrderDetailModel): void {
    this.dialog.open(SupplierOrderModal, {
      data: {
        supplierOrderDetail: d,
        mode: CRUD_MODE_VIEW,
        itemGroups: this.itemGroups,
      }
    });
  }

  edit(d: SupplierOrderDetailModel): void {
    const dialog = this.dialog.open(SupplierOrderModal, {
      data: {
        supplierOrderDetail: d,
        mode: CRUD_MODE_EDIT,
        itemGroups: this.itemGroups,
      }
    });
    dialog.componentInstance.result.subscribe(r => {
      const index = this.orderDetails.findIndex(od => od.itemGroupId === r.itemGroupId);
      if (index >= 0) {
        this.orderDetails[index].subtotal = r.subtotal;
        this.orderDetails[index].quantity = r.quantity;
        this.total = 0;
        this.orderDetails.forEach(dx => {
          this.total += dx.subtotal;
        });
      }
    });
  }

  delete(d: SupplierOrderDetailModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.itemGroupName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        const index = this.orderDetails.findIndex(od => od.itemGroupId === d.itemGroupId);
        this.orderDetails.splice(index, 1);
        this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
          verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
        });
      }
    });
  }

  async uploadFile(id: number): Promise<any> {
    return await this.supplierOrderService.uploadFile(this.fd, id);
  }

  paid(): void {
    this.doBeforeSave('paid', new Date());
  }

  verified(): void {
    this.doBeforeSave('verified', new Date());
  }

  sent(): void {
    this.doBeforeSave('sent', new Date());
  }

  pendingPayment(): void {
    this.doBeforeSave('pending', new Date());
  }

  downloadEvidence(): void {
    this.supplierOrderService.downloadEvidenceFile(this.supplierOrder.id).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.supplierOrder.internalInvoiceNo + '.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  downloadInvoice(): void {
    this.supplierOrderService.downloadFile(this.supplierOrder.id).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded-file.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  cancel(): void {
    this.router.navigate(['/supplier/order']).then();
  }
}
