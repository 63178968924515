<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50">

      <div class="mat-card-content px-3 mt-3 py-5">

          <mat-accordion class="example-headers-align mt-3" multi >
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'pages.area.area' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <form [formGroup]="form" (submit)="save()">
                <div class="row">
                  <div class="col-4">
                    <app-text-field [readonly]="readonly" [label]="'pages.area.area-name' | translate" formControlName="areaName"></app-text-field>
                  </div>
                  <div class="col-4">
                    <app-number-field [readonly]="readonly" [label]="'pages.area.delivery-fee' | translate" formControlName="deliveryFee" ></app-number-field>
                  </div>
                  <div class="col-4">
                    <app-number-field [readonly]="readonly" [label]="'pages.area.free-delivery-min-order' | translate" formControlName="freeDeliveryMinOrder" ></app-number-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <app-area-field [readonly]="readonly" [label]="'pages.area.area-description' | translate" formControlName="areaDescription" [rows]="10" [cols]="10"></app-area-field>
                  </div>
                  <div class="col-6 pt-2">
                    <app-upload-single
                      [readonly]="readonly"
                      [fileUrl]="fileUrl"
                      [accept]="'image/*'"
                      [label]="'pages.area.image' | translate"
                      [labelButton]="'pages.area.image-button' | translate" (fileChange)="getFileChange($event)">

                    </app-upload-single>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 justify-content-end text-end">
                    <button mat-raised-button color="primary">{{'common.edit' | translate}} {{'pages.area.area' | translate}}</button>
                  </div>
                </div>
              </form>

            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'pages.area-sub.area-sub' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="mat-card-content ">
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <label>{{'common.search' | translate}}</label>
                      <div class="input-group mb-3">
                        <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
                        <div class="input-group-append">
                        <span class="input-group-text" id="suffix_password_show">
                            <span class="icon-search"></span>
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8 pt-3 text-end">
                    <button (click)="create()" mat-raised-button color="primary" class="bg-brand text-white">{{'common.create' | translate}}</button>
                  </div>
                </div>
              </div>
              <div class="mat-card-content pb-5">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th class="text-nowrap text-end">{{'pages.area-sub.id' | translate}}</th>
                      <th class="text-nowrap">{{'pages.area-sub.name' | translate}}</th>
                      <th class="text-nowrap" style="width: 320px;">{{'pages.area-sub.description' | translate}}</th>
                      <th class="sticky-column text-center" style="width: 250px;">{{'common.action' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let d of pageItems">
                      <td class="text-end">{{d.id}}</td>
                      <td>{{d.subAreaName}}</td>
                      <td>{{d.subAreaDescription}}</td>
                      <td class="sticky-column">
                        <button (click)="view(d)" type="button" mat-button color="primary">
                          <mat-icon>fullscreen</mat-icon>
                        </button>
                        <button (click)="edit(d)" mat-button class="text-orange" color="primary">
                          <mat-icon>edit_square</mat-icon>
                        </button>
                        <button (click)="delete(d)" type="button"  mat-button color="warn">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="mat-card-content">
                <div class="row mb-4">
                  <div class="col-12 text-center">
                    <app-pagination
                      (goPage)="toPage($event)"
                      [pagesToShow]="showPages"
                      [page]="pageNo"
                      [perPage]="pageSize"
                      [count]="totalRow"></app-pagination>
                  </div>
                </div>
              </div>

            </mat-expansion-panel>

          </mat-accordion>

      </div>

    </div>
  </div>
</div>
