import { Component, OnInit, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { ParameterModal } from '../modals/parameter.modal';
import { ParameterModel } from '../models/parameter.model';
import { ParameterService } from '../services/parameter.service';

@Component({
  selector: 'app-page-parameter',
  templateUrl: './parameter.page.html',
})
export class ParameterPageComponent extends BasePageComponentDirective<ParameterModel> implements OnInit{
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private parameterService: ParameterService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false, route: ''},
      {label: 'pages.product-category.product-category', active: true, route: ''}
    ]);
  }

  query(): void {
  }

  create(): void {
    const dialog = this.dialog.open(ParameterModal, {
      data: {
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }


}
