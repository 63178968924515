<div class="main-content homepage grey-background">
    <div class="container-fluid">
        <div class="mat-card m- white-background text-black-50 rounded-lg">
            <div class="mat-card-title px-3 py-3">
                <h3 class="pl-2">{{'pages.supplier.order-receiving' | translate}}</h3>
            </div>
            <div class="mat-card-content px-3 rounded">
                <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label>{{'common.search' | translate}}</label>
                        <div class="input-group mb-3">
                          <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
                          <div class="input-group-append">
                                <span class="input-group-text" id="suffix_password_show">
                                    <span class="icon-search"></span>
                                </span>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-8 pt-3 text-end">

                    </div>
                </div>
            </div>
            <div class="mat-card-content px-3 pb-5">
              <div class="table-responsive">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th class="text-nowrap">{{'pages.supplier.internal-invoice-no' | translate}}</th>
                        <th class="text-nowrap">{{'pages.supplier.supplier-invoice-no' | translate}}</th>
                        <th class="text-nowrap">{{'pages.supplier.order-date' | translate}}</th>
                        <th class="text-nowrap">{{'pages.supplier.supplier-name' | translate}}</th>
                        <th class="text-nowrap">{{'pages.supplier.status' | translate}}</th>
                        <th class="text-nowrap">{{'pages.supplier.stock-status' | translate}}</th>
                        <th class="sticky-column text-center" style="padding-top: 25px;">{{'common.action' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let d of pageItems">
                        <td class="text-nowrap">{{d.internalInvoiceNo}}</td>
                        <td class="text-nowrap">{{d.supplierInvoiceNo ? d.supplierInvoiceNo : '-'}}</td>
                        <td class="text-nowrap">{{d.orderDate | date}}</td>
                        <td class="text-nowrap">{{maskingColumn('supplierName', 'id', suppliers, d.supplierId)}}</td>
                        <td class="text-nowrap">{{d.status.toUpperCase()}}</td>
                        <td class="text-nowrap">{{d.stockStatus.toUpperCase()}}</td>
                        <td class="sticky-column">
                            <div class="btn-group" role="group" aria-label="Button Action">
                                <button (click)="edit(d)" mat-button class="text-orange" color="primary">
                                  <mat-icon>edit_square</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div class="mat-card-content">
                <div class="row mt-4 mb-4">
                    <div class="col-12 text-center">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
