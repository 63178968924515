<div class="white-background p-5">
    <form [formGroup]="form" (submit)="save()">
      <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.item-group.editor' | translate}}</h4>
      <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.item-group.editor' | translate}} #{{itemGroup.id}}</h2>
      <mat-dialog-content style="width: 600px;">

        <div class="row">
          <div class="col-12">
            <app-text-field [disabling]="true"
                            [label]="'pages.item-group.item-group-code' | translate"
                            formControlName="itemGroupCode">
            </app-text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-text-field [readonly]="readonly"
                            [label]="'pages.item-group.item-group-name' | translate"
                            formControlName="itemGroupName"
                            (valueChange)="generateItemGroupCode()">
            </app-text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <app-select [readonly]="readonly"
                        [items]="products"
                        [bindLabel]="'productName'"
                        [bindValue]="'id'"
                        [label]="'pages.product.product' | translate"
                        formControlName="productId">
            </app-select>
          </div>
          <div class="col-6">
            <app-select [readonly]="readonly"
                        [items]="subProducts"
                        [bindLabel]="'subProductName'"
                        [bindValue]="'id'"
                        [label]="'pages.product-sub.product-sub' | translate"
                        (valueChange)="generateItemGroupCode()"
                        formControlName="subProductId">
            </app-select>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <app-select [readonly]="readonly"
                        [items]="units"
                        [bindLabel]="'value'"
                        [bindValue]="'key'"
                        [label]="'pages.item.unit' | translate"
                        formControlName="unit">
            </app-select>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-select *ngIf="this.mode == 'edit'"
                        [readonly]="true"
                        [items]="suppliers"
                        [bindLabel]="'supplierName'"
                        [bindValue]="'id'"
                        [label]="'pages.supplier.supplier-name' | translate"
                        formControlName="supplierId">
            </app-select>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <app-number-field [readonly]="true"
                              formControlName="buyPrice"
                              [label]="'pages.item.buy-price' | translate">
            </app-number-field>
          </div>
          <div class="col-6">
            <app-number-field [readonly]="true"
                              formControlName="standardPrice"
                              [label]="'pages.item.standard-price' | translate">
            </app-number-field>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <app-area-field [readonly]="readonly"
                            [label]="'pages.item-group.description' | translate"
                            formControlName="description"
                            [rows]="10"
                            [cols]="10">
            </app-area-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-upload-single
              [readonly]="readonly"
              [fileUrl]="fileUrl"
              [accept]="'image/*'"
              [label]="'pages.item-group.image' | translate"
              [labelButton]="'pages.item-group.image-button' | translate" (fileChange)="getFileChange($event)">

            </app-upload-single>
          </div>
        </div>

      </mat-dialog-content>
      <mat-dialog-actions  class="justify-content-end pe-4">
        <button *ngIf="!viewMode"
                mat-raised-button
                class="py-1"
                color="primary">{{'common.save' | translate}}</button>

        <button type="button"
                mat-dialog-close
                mat-stroked-button
                class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
      </mat-dialog-actions>
    </form>
  </div>
