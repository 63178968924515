import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { AreaSubPageComponent } from './area-sub/pages/area-sub.page';
import { AreaPageComponent } from './area/pages/area.page';
import { ItemPageComponent } from '../item/item/pages/item.page';
import { MarginPageComponent } from './margin/pages/margin.page';
import { ParameterPageComponent } from './parameter/pages/parameter.page';
import { ProductSubPageComponent } from './product-sub/pages/product-sub.page';
import { ProductPageComponent } from './product/pages/product.page';
import { StoragePageComponent } from './storage/pages/storage.page';

const routes: Routes = [
  {path: 'area', component: AreaPageComponent, canActivate: [AuthGuard]},
  {path: 'area/:id', component: AreaSubPageComponent, canActivate: [AuthGuard]},
  {path: 'margin', component: MarginPageComponent, canActivate: [AuthGuard]},
  {path: 'product', component: ProductPageComponent, canActivate: [AuthGuard]},
  {path: 'product/:id', component: ProductSubPageComponent, canActivate: [AuthGuard]},
  {path: 'storage', component: StoragePageComponent, canActivate: [AuthGuard]},
  {path: 'parameter', component: ParameterPageComponent, canActivate: [AuthGuard]},
  {path: 'item', component: ItemPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterRoutesModule {
}
