<div class="main-content homepage grey-background">
    <div class="container-fluid">
      <div class="mat-card m- white-background text-black-50 rounded-lg">
        <div class="mat-card-title px-3 py-3">
          <h3 class="pl-2">{{'pages.customer.customer' | translate}}</h3>
        </div>
        <div class="mat-card-content px-3 rounded">
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label>{{'common.search' | translate}}</label>
                <div class="input-group mb-3">
                  <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
                  <div class="input-group-append">
                    <span class="input-group-text" id="suffix_password_show">
                        <span class="icon-search"></span>
                    </span>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-8 pt-3 text-end">
              <button (click)="create()" mat-flat-button color="primary" class="text-white">{{'common.create' | translate}}</button>
            </div>
          </div>
        </div>
        <div class="mat-card-content px-3 pb-3">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-nowrap text-end">{{'pages.customer.id' | translate}}</th>
                  <th class="text-nowrap">{{'pages.customer.customer-name' | translate}}</th>
                  <th class="text-nowrap">{{'pages.customer.contact-person' | translate}}</th>
                  <th class="text-nowrap">{{'pages.customer.phone-number' | translate}}</th>
                  <th class="text-nowrap">{{'pages.area.area-name' | translate}}</th>
                  <th class="text-nowrap">{{'pages.area-sub.name' | translate}}</th>
                  <th class="text-nowrap">{{'pages.customer.address' | translate}}</th>
                  <th class="sticky-column text-center">{{'common.action' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let d of pageItems">
                    <td class="text-nowrap text-end">{{d.id}}</td>
                    <td class="text-nowrap">{{d.customerName}}</td>
                    <td class="text-nowrap">{{d.contactPerson}}</td>
                    <td class="text-nowrap">{{d.phoneNumbers}}</td>
                    <td class="text-nowrap">{{maskingColumn('areaName', 'id', areas, d.areaId)}}</td>
                    <td class="text-nowrap">{{maskingColumn('subAreaName', 'id', subAreas, d.subAreaId)}}</td>
                    <td class="text-nowrap">{{d.address}}</td>
                    <td class="sticky-column">
                      <div class="btn-group" role="group" aria-label="Button Action">
                        <button (click)="view(d)" type="button" mat-button color="primary">
                          <mat-icon>fullscreen</mat-icon>
                        </button>
                        <button (click)="edit(d)" mat-button class="text-orange" color="primary">
                          <mat-icon>edit_square</mat-icon>
                        </button>
                        <button (click)="delete(d)" type="button"  mat-button color="warn">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mat-card-content">
          <div class="row mb-4">
            <div class="col-12 text-center">
              <app-pagination
                (goPage)="toPage($event)"
                [pagesToShow]="showPages"
                [page]="pageNo"
                [perPage]="pageSize"
                [count]="totalRow"></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
