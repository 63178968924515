import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {CustomerOrderModel} from '../models/customer-order.model';
import {CustomerOrderService} from '../services/customer-order.service';
import {CustomerModel} from '../../customer/models/customer.model';
import {CustomerService} from '../../customer/services/customer.service';
import { STATUS_LIST } from '../../../../constants/common.constant';
import { SupplierModel } from '../../../supplier/supplier/models/supplier.model';

@Component({
  selector: 'app-page-customer-order',
  templateUrl: './customer-order.page.html',
})
export class CustomerOrderPageComponent extends BasePageComponentDirective<CustomerOrderModel> implements OnInit{

  customers: CustomerModel[] = [];
  stockStatuses = STATUS_LIST;
  suppliers: SupplierModel[] = [];
  status = '';
  datePick = '';
  customerName = '';
  constructor(
    injector: Injector,
    private customerOrderService: CustomerOrderService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.query();

    this.customers = await this.customerService.findAllNoPaging();

    this.breadcrumbService.changeMessage([
      {label: 'pages.customer.customer', active: false},
      {label: 'pages.customer.customer-order', active: true, route: ''}
    ]);
  }

  getChanged(field: string, $event): void {
    console.log(field, $event, this.datePick, this.status);

    if (field === 'date') {
      this.datePick = $event ? this.date.momentToDbDate($event) : '';
    } else {
      this.status = $event ? $event : '';
    }

    if (!this.status) { this.status = ''; }
    if (!this.datePick) { this.datePick = ''; }
    this.query();
  }

  query(): void {
    this.customerOrderService.findAllBySearch(this.pageQuery, this.customerName, this.datePick, this.status).then(async (res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    this.router.navigate(['/customer/order/add-new/0']).then();
  }

  edit(d: CustomerOrderModel): void {
    this.router.navigate(['/customer/order/edit/' + d.id]).then();
  }

  view(d: CustomerOrderModel): void {
    this.router.navigate(['/customer/order/view/' + d.id]).then();
  }

  delete(d: CustomerOrderModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.invoiceNo}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.customerOrderService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

}
