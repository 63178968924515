import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BACKOFFICE, IMAGE_BASE_URL, ITEM_GROUP_BASE_URL } from 'src/app/constants/api.constant';
import { BasePageService } from 'src/app/shared/base/base-page.service';
import { environment } from 'src/environments/environment';
import { ItemGroupModel } from '../models/item-group.model';

@Injectable({providedIn: 'root'})
export class ItemGroupService extends BasePageService<ItemGroupModel>{
  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + ITEM_GROUP_BASE_URL;
  }

  async findBySupplierId(supplierId: number): Promise<ItemGroupModel[]> {
    return await this.http.get<ItemGroupModel[]>(this.baseUrl + '/supplier/' + supplierId).toPromise();
  }

  async uploadFile(fd: FormData, itemGroupId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${itemGroupId}/upload/item-group`,
        fd, {}).toPromise();
  }

  async reportByStorage(storageId: number, areaId: number, reportType: string, productId: number, option: any): Promise<any> {
    productId = productId ? productId : 0;
    return await this.http.get<any>(this.baseUrl + `/report/storage/${storageId}/${areaId}/${reportType}/${productId}/${option.hasSupplier}/${option.hasRemindStockWhen}/${option.hasBuyPrice}/${option.hasCurrentStock}`, {
      responseType: 'blob' as 'json'
    })
      .toPromise();
  }

  async generateItemGroupCode(subProductName: string, itemGroupName: string, itemGroupCode: string): Promise<any> {
    return await this.http.post<any>(this.baseUrl + `/generate/item-group-code`,
      {subProductName, itemGroupName, itemGroupCode}, {}).toPromise();
  }
}
