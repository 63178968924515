import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { environment } from 'src/environments/environment';
import { ItemGroupModal } from '../modals/item-group.modal';
import { ItemGroupService } from '../services/item-group.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { ItemGroupModel } from '../models/item-group.model';
import { ProductModel } from 'src/app/pages/master/product/models/product.model';
import { ProductSubModel } from 'src/app/pages/master/product-sub/models/product-sub.model';
import { ProductService } from 'src/app/pages/master/product/services/product.service';
import { ProductSubService } from 'src/app/pages/master/product-sub/services/product-sub.service';
import {SupplierModel} from '../../../supplier/supplier/models/supplier.model';
import {SupplierService} from '../../../supplier/supplier/services/supplier.service';

@Component({
    selector: 'app-page-item-group',
    templateUrl: './item-group.page.html',
  })
export class ItemGroupPageComponent extends BasePageComponentDirective<ItemGroupModel> implements OnInit{
  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  suppliers: SupplierModel[] = [];
  itemImageBaseURL = environment.storage + '/images/item-group/';

  constructor(
      injector: Injector,
      private itemGroupService: ItemGroupService,
      private productService: ProductService,
      private subProductService: ProductSubService,
      private supplierService: SupplierService,
      private dialog: MatDialog,
      private breadcrumbService: BreadcrumbService
  ) {
      super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.products = await this.productService.findAllNoPaging();
    this.subProducts = await this.subProductService.findAllNoPaging(0);
    this.suppliers = await this.supplierService.findAllNoPaging();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.item-group.index', active: false, route: ''},
      {label: 'pages.item-group.item-group', active: true, route: ''}
    ]);
  }

  query(): void {
    this.itemGroupService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    const dialog = this.dialog.open(ItemGroupModal, {
      data: {
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: ItemGroupModel): void {
    this.itemGroupService.findOne(d.id).then(itemGroup => {
      itemGroup.imageName = (itemGroup.imageName) ? environment.storage + '/images/item-group/' + itemGroup.imageName : null;
      const dialog = this.dialog.open(ItemGroupModal, {
        data: {
          itemGroup,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: ItemGroupModel): void {
    this.itemGroupService.findOne(d.id).then(itemGroup => {
      itemGroup.imageName = (itemGroup.imageName) ? environment.storage + '/images/item-group/' + itemGroup.imageName : null;
      this.dialog.open(ItemGroupModal, {
        data: {
          itemGroup,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: ItemGroupModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.itemGroupName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.itemGroupService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

  detail(d: ItemGroupModel): void {
    this.router.navigate(['item-group/item-group/' + d.id]).then();
  }
}
